import ProjectNav from '../../components/ProjectNav'
import {
    poch1,
    poch2
} from '../../images/projects/poch/imagesPoch'
const Poch = () => {
    const nav = (
        <ProjectNav
            prevProj='iefp'
            nextProj='espap'
            projYear='2015'
            projUrl='http://poch.portugal2020.pt/'
        />
    )
    return (
        <div className='projectpage main poch'>
            <div className='linha'></div>
            <section className='container'>
                <h1>POCH Institutional Website</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            POCH is part of Portugal 2020 (EU investment partnership). The project was done
                            in short time with a challenging logo and color
                            palette. Integration between front end and
                            Sharepoint was easy due to experience in past
                            projects.
                        </p>
                        <ul className='techs'>
                            <li>Design</li>
                            <li>Front End</li>
                            <li>Responsive</li>
                            <li>WCAG 2.0 - AA</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img alt='Homepage' src={poch1} />
                    <p>Homepage. All diagonals were coded without CSS3 because of compatibility issues ate the time.</p>
                    <img alt='Programme' src={poch2} />
                    <p>Programme page</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Poch
