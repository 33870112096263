import { useEffect } from 'react'
import sr from '../modules/ScrollReveal'
import {
  altran,
  artesis,
  easol,
  escs,
  gf,
  hypnotic,
  janssen,
  ss,
  bose,
  lv
} from '../images/imgIndex'

const Timeline = () => {
  useEffect(() => {
    sr.reveal('.half.esq *, .half.smalldevice *', {
      origin: 'left',
      distance: '500px'
    })

    sr.reveal('.half.dir *', {
      origin: 'right',
      distance: '500px'
    })
  }, [])

  return (
    <div className='biobox'>
      <ul id='bioList' className='clearfix'>
        <li className='easol'>
          <div className='half esq'>
            <img alt='lv' src={easol} />
          </div>
          <div className='half dir'>
            <h2>EASOL</h2>
            <p>Front End Developer</p>
          </div>
        </li>
        <li className='bose'>
          <div className='half smalldevice'>
            <img alt='Bose' src={bose} />
          </div>
          <div className='half esq'>
            <h2>Bose</h2>
            <p>Front End Developer</p>
          </div>
          <div className='half dir smalldevicehidden'>
            <img alt='Bose' src={bose} />
          </div>
        </li>
        <li className='lv'>
          <div className='half esq'>
            <img alt='lv' src={lv} />
          </div>
          <div className='half dir'>
            <h2>LEGALVISION</h2>
            <p>Web & Graphic Designer, Front End Developer</p>
          </div>
        </li>
        <li className='jnj'>
          <div className='half smalldevice'>
            <img alt='janssen' src={janssen} />
          </div>
          <div className='half esq'>
            <h2>
              Janssen-Cilag
              <br />
              <small>Johnson & Johnson</small>
            </h2>
            <p>Web & Graphic Designer, Digital Analyst, Sharepoint Admin</p>
          </div>
          <div className='half dir smalldevicehidden'>
            <img alt='janssen' src={janssen} />
          </div>
        </li>
        <li className='altran'>
          <div className='half esq'>
            <img alt='altran' src={altran} />
          </div>
          <div className='half dir'>
            <h2>Altran</h2>
            <p>Web & Graphic Designer, Front End Developer</p>
          </div>
        </li>
        <li className='hypnotic'>
          <div className='half smalldevice'>
            <img alt='hypnotic' src={hypnotic} />
          </div>
          <div className='half esq'>
            <h2>Hypnotic</h2>
            <p>Web & Graphic Designer, Full Stack Developer, Video, Flash</p>
          </div>
          <div className='half dir smalldevicehidden'>
            <img alt='hypnotic' src={hypnotic} />
          </div>
        </li>
        <li className='simplesample'>
          <div className='half esq'>
            <img alt='ss' src={ss} />
          </div>
          <div className='half dir'>
            <h2>Simplesample</h2>
            <p>Web & Graphic Designer, Full Stack Developer, 3D</p>
          </div>
        </li>
        <li className='groundforce'>
          <div className='half smalldevice'>
            <img alt='groundforce' src={gf} />
          </div>
          <div className='half esq'>
            <h2>Groundforce</h2>
            <p>TTAE Pax / Ramp</p>
          </div>
          <div className='half dir smalldevicehidden'>
            <img alt='groundforce' src={gf} />
          </div>
        </li>
        <li className='artesis'>
          <div className='half esq'>
            <img alt='artesis' src={artesis} />
          </div>
          <div className='half dir'>
            <h2>
              Artesis Hogeschool <br />
              <small>Antwerpen, Belgium</small>
            </h2>
            <p>Mobile Applications Development Intensive Programme</p>
          </div>
        </li>
        <li className='escs'>
          <div className='half smalldevice'>
            <img alt='escs' src={escs} />
          </div>
          <div className='half esq'>
            <h2>Escola Superior de Comunicação Social</h2>
            <p>Degree in Audiovisual and Multimedia</p>
          </div>
          <div className='half dir smalldevicehidden'>
            <img alt='escs' src={escs} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Timeline
