import React from 'react'
import HexChart from '../components/HexChart'

const Skills = () => {
    return (
        <div className='main skillspage'>
            <div className='linha'></div>
            <section>
                <h1>Skills</h1>
                <div className='graph'>
                    <h3>Project Cycle</h3>
                    <HexChart />
                    <ul>
                        <li><b>CIA</b>Concept & Information Architecture</li>
                        <li><b>DP</b>Design & Prototyping</li>
                        <li><b>FE</b>Front End</li>
                        <li><b>BE</b>Back End</li>
                        <li><b>T</b>Testing</li>
                        <li><b>UCF</b>User & Client Feedback</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Skills
