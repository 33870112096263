import { useState, useEffect } from 'react'
import $ from 'jquery'
import { scsxred } from '../images/imgIndex'
import { Link } from 'react-router-dom'
import MobileContacts from './MobileContacts'

const Cube = () => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        function checkMobile() {
            setIsMobile(window.innerWidth <= 991)
        }
        checkMobile()
        window.addEventListener('resize', checkMobile)
        return () => {
            window.removeEventListener('resize', checkMobile)
        }
    }, [isMobile])

    useEffect(() => {
        function runCube() {
            let el = document.createElement('div')
            let transformProps =
                'transform WebkitTransform MozTransform OTransform msTransform'.split(
                    ' '
                )
            let transformProp = support(transformProps)
            let transitionDuration =
                'transitionDuration WebkitTransitionDuration MozTransitionDuration OTransitionDuration msTransitionDuration'.split(
                    ' '
                )
            let transitionDurationProp = support(transitionDuration)
            function support(props) {
                for (let i = 0, l = props.length; i < l; i++) {
                    if (typeof el.style[props[i]] !== 'undefined') {
                        return props[i]
                    }
                }
            }

            let mouse = {
                start: {}
            }
            let touch = document.ontouchmove !== undefined

            let viewport = {
                x: -10,
                y: 20,
                el: document.getElementsByClassName('cube')[0],
                move: function (coords) {
                    if (coords) {
                        if (typeof coords.x === 'number') this.x = coords.x

                        if (typeof coords.y === 'number') this.y = coords.y
                    }
                    this.el.style[transformProp] =
                        'rotateX(' + this.x + 'deg) rotateY(' + this.y + 'deg)'
                },

                reset: function () {
                    this.move({ x: 0, y: 0 })
                }
            }

            viewport.duration = (function () {
                let d = touch ? 50 : 500
                viewport.el.style[transitionDurationProp] = d + 'ms'
                return d
            })()

            $('#contact').on('mousedown touchstart', function (evt) {
                delete mouse.last
                if ($(evt.target).is('a, iframe')) {
                    return true
                }
                mouse.start.x = evt.pageX
                mouse.start.y = evt.pageY
                $(document).on('mousemove touchmove', function (event) {
                    if (
                        !touch ||
                        !(
                            event.originalEvent &&
                            event.originalEvent.touches.length > 1
                        )
                    ) {
                        event.preventDefault()
                        $('.viewport').trigger('move-viewport', {
                            x: event.pageX,
                            y: event.pageY
                        })
                    }
                })

                $(document).on('mouseup touchend', function () {
                    $(document).off('mousemove touchmove')
                })
            })

            $('.viewport').on('move-viewport', function (evt, movedMouse) {
                let movementScaleFactor = touch ? 4 : 1
                if (!mouse.last) {
                    mouse.last = mouse.start
                } else {
                    if (
                        forward(mouse.start.x, mouse.last.x) !==
                        forward(mouse.last.x, movedMouse.x)
                    ) {
                        mouse.start.x = mouse.last.x
                    }
                    if (
                        forward(mouse.start.y, mouse.last.y) !==
                        forward(mouse.last.y, movedMouse.y)
                    ) {
                        mouse.start.y = mouse.last.y
                    }
                }

                viewport.move({
                    x:
                        viewport.x +
                        parseInt(
                            (mouse.start.y - movedMouse.y) / movementScaleFactor
                        ),
                    y:
                        viewport.y -
                        parseInt(
                            (mouse.start.x - movedMouse.x) / movementScaleFactor
                        )
                })

                mouse.last.x = movedMouse.x
                mouse.last.y = movedMouse.y
                function forward(v1, v2) {
                    return v1 >= v2 ? true : false
                }
            })
        }

        runCube()
    }, [isMobile])

    return (
        <>
            <article className='viewport mobiledevicehidden'>
                <section className='cube'>
                    <div className='linkedin'>
                        <p>
                            <a
                                href='https://pt.linkedin.com/in/jorgesoucasaux'
                                title='Linkedin'
                                rel='noreferrer'
                                target='_blank'>
                                <i className='icon-linkedin'></i>LINKEDIN
                            </a>
                        </p>
                    </div>
                    <div className='github'>
                        <p>
                            <a
                                href='https://github.com/scsx'
                                title='Linkedin'
                                rel='noreferrer'
                                target='_blank'>
                                <i className='icon-github'></i>GITHUB
                            </a>
                        </p>
                    </div>
                    <div className='logo'>
                        <img alt='img' src={scsxred} />
                    </div>
                    <div className='spotify'>
                        <p>
                            <a
                                href='https://open.spotify.com/user/1179088849'
                                rel='noreferrer'
                                target='_blank'>
                                <i className='icon-spotify'></i>SPOTIFY
                            </a>
                        </p>
                    </div>
                    <div className='projects'>
                        <p>
                            <Link to='/projects'>
                                <i className='icon-heart'></i>PROJECTS
                            </Link>
                        </p>
                    </div>
                    <div className='cv'>
                        <p>
                            <Link to='/cv'>
                                <i className='icon-adult'></i>RÉSUMÉ
                            </Link>
                        </p>
                    </div>
                </section>
            </article>

            <p className='box altctct'>(Click and drag the cube)</p>

            <MobileContacts />
        </>
    )
}

export default Cube
