import { useEffect } from 'react'
import $ from 'jquery'
import SkillBox from '../components/SkillBox'
import Brands from '../components/Brands'
import Timeline from '../components/Timeline'
import Cube from '../components/Cube'

const HomePage = () => {
    const backToTopHandler = () => {
        $('html, body').animate({ scrollTop: 0 }, 1000)
    }

    useEffect(() => {
        $('.main').removeClass('loading')
    }, [])

    return (
        <div className='main loading'>
            <div className='linha'></div>
            <section className='page hall'>
                <h1>Jorge Soucasaux</h1>
                <SkillBox />
                <h4 className='box intro'>
                    I'm a versatile web designer, front end developer and
                    problem solver. I have 10+ years of experience working in
                    both small and large agencies and projects
                </h4>
            </section>

            <section className='page work'>
                <h2 className='box'>BRANDS I'VE WORKED WITH™</h2>
                <Brands />
            </section>

            <section className='page bio'>
                <h2 className='box'>TIMELINE</h2>
                <Timeline />
            </section>

            <section className='page contact' id='contact'>
                <h2 className='box'>CONTACT</h2>
                <Cube />
            </section>

            <div className='backtop' onClick={backToTopHandler}>
                <div className='box'></div>
            </div>
        </div>
    )
}

export default HomePage
