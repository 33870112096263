import ProjectNav from '../../components/ProjectNav'
import { nj1, nj2, nj3, nj4 } from '../../images/projects/netjets/imagesNj'
const Netjets = () => {
    const nav = (
        <ProjectNav
            prevProj='amn'
            nextProj='iefp'
            projYear='2012'
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main nj'>
            <div className='linha'></div>
            <section className='container'>
                <h1>NetJets Environmental Report 2012</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            NetJets was the first private business jet charter
                            and aircraft management company in the world. It's
                            fleet is the largest private jet fleet in the world
                            with more than 650 aircraft worldwide. Every year it
                            publishes an Annual report to inform it's customers
                            and stakeholders about it's environmental strategies
                            and results.
                        </p>
                        <p>
                            Provided by the Art Director with general guidelines
                            I was responsible for the execution of the document
                            and iteractions with the client.
                        </p>
                        <ul className='techs'>
                            <li>Graphic design</li>
                            <li>Indesign</li>
                            <li>Illustrator</li>
                            <li>Photoshop</li>
                            <li>Print</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img alt='Cover' src={nj1} />
                    <p>Report cover.</p>
                    <img alt='Pages' src={nj2} />
                    <p></p>
                    <img alt='Overview' src={nj3} />
                    <p>
                        Overview. I can't provide a greater resolution to ensure
                        the content's privacy.
                    </p>
                    <img alt='Printed result' src={nj4} />
                    <p>Final printed result on recycled paper.</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Netjets
