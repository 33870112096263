import ProjectNav from '../../components/ProjectNav'
import { espap1, espap2, espap3 } from '../../images/projects/espap/imagesEspap'
const Espap = () => {
    const nav = (
        <ProjectNav
            prevProj='poch'
            nextProj='lv'
            projYear='2014'
            projUrl='https://www.espap.gov.pt'
        />
    )
    return (
        <div className='projectpage main espap'>
            <div className='linha'></div>
            <section className='container'>
                <h1>eSPap Institutional Website & Intranet</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            These are actually 2 projects but my intervention on
                            both was similar. There were numerous iterations
                            until the final results but in the end the result
                            was the same: a great relationship with the client
                            and websites that suit it's needs.
                        </p>
                        <ul className='techs'>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>Front End</li>
                            <li>Responsive</li>
                            <li>WCAG 2.0 - AA</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img src={espap1} alt='Homepage' />
                    <p>Homepage</p>
                    <img src={espap2} alt='Some pages' className='noborder' />
                    <p>Some pages and details from the intranet</p>
                    <img src={espap3} alt='cheat sheet' />
                    <p>
                        Technical "cheat sheet" for the newsletter
                        administrators
                    </p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Espap
