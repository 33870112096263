import { Route, Redirect } from 'react-router-dom'
const GuardedRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                auth === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`/password#${rest.path}`} />
                )
            }
        />
    )
}

export default GuardedRoute
