import { Link } from 'react-router-dom'

const ProjectNav = (props) => {
    return (
        <nav className='nav'>
            <Link to='/projects' className='back'>
                <i className='icon-left-open-big'></i>
                <i className='icon-left-open-big'></i>
            </Link>
            {props.prevProj && (
                <Link to={`/projects/${props.prevProj}`}>
                    <i className='icon-left-open-big'></i>
                </Link>
            )}
            {props.nextProj && (
                <Link to={`/projects/${props.nextProj}`}>
                    <i className='icon-right-open-big'></i>
                </Link>
            )}
            <div className='ano'>{props.projYear}</div>
            {props.projUrl && (
                <a
                    href={props.projUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='visit'>
                    <i className='icon-plus-1'></i>
                </a>
            )}
            {/* <div className="empty"></div> */}
        </nav>
    )
}

export default ProjectNav
