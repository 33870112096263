import { useContext } from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import AuthContext from './auth/auth-context'
import LogoScsx from './images/scsx.svg'

import Password from './auth/Password'
import HomePage from './pages/HomePage'
import Cv from './pages/Cv'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import Projects from './pages/Projects'
import GuardedRoute from './auth/GuardedRoute'

import Amn from './pages/projects/Amn'
import Netjets from './pages/projects/Netjets'
import Iefp from './pages/projects/Iefp'
import Poch from './pages/projects/Poch'
import Espap from './pages/projects/Espap'
import Lv from './pages/projects/Lv'
import Kudos from './pages/projects/Kudos'
import Maintra from './pages/projects/Maintra'
import Amo from './pages/projects/Amo'
import Misc from './pages/projects/Misc'
import Logo from './pages/projects/Logo'
import Skills from './pages/Skills'

//import TempLogout from './auth/TempLogout'

function App() {
    const authCtx = useContext(AuthContext)

    return (
        <div className='App'>
            <div className='mainheader'>
                <div className='square logo'>
                    <Link to='/'>
                        <img src={LogoScsx} alt='homepage' />
                    </Link>
                </div>
            </div>

            <Switch>
                <Route path='/' exact>
                    <HomePage />
                </Route>
                <Route path='/contact/'>
                    <Contact />
                </Route>
                <Route path='/projects/' exact>
                    <Projects />
                </Route>
                <Route path='/skills/'>
                    <Skills />
                </Route>
                <Route path='/password/'>
                    {!authCtx.isLoggedIn && <Password />}
                    {authCtx.isLoggedIn && <Redirect to='/' />}
                </Route>
                {/* PROTECTED */}
                <GuardedRoute
                    path='/projects/netjets'
                    component={Netjets}
                    auth={authCtx.isLoggedIn}
                />
                <GuardedRoute
                    path='/projects/maintra'
                    component={Maintra}
                    auth={authCtx.isLoggedIn}
                />
                <GuardedRoute
                    path='/projects/amo'
                    component={Amo}
                    auth={authCtx.isLoggedIn}
                />
                <GuardedRoute
                    path='/cv'
                    component={Cv}
                    auth={authCtx.isLoggedIn}
                />
                <Route path='/projects/amn'>
                    <Amn />
                </Route>
                <Route path='/projects/iefp'>
                    <Iefp />
                </Route>
                <Route path='/projects/poch'>
                    <Poch />
                </Route>
                <Route path='/projects/espap'>
                    <Espap />
                </Route>
                <Route path='/projects/lv'>
                    <Lv />
                </Route>
                <Route path='/projects/kudos'>
                    <Kudos />
                </Route>
                <Route path='/projects/misc'>
                    <Misc />
                </Route>
                <Route path='/projects/logo'>
                    <Logo />
                </Route>
                <Route path='*'>
                    <NotFound />
                </Route>
            </Switch>
        </div>
    )
}

export default App
