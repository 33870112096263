import { useEffect } from 'react'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
)

const HexChart = () => {
    const data = {
        labels: ['CIA', 'DP', 'FE', 'BE', 'T', 'UCF'],
        legend: {
            display: false
        },
        datasets: [
            {
                label: 'Experience and Interest',
                data: [4.8, 4.4, 5.5, 1.1, 4.3, 4.5],
                backgroundColor: '#ff400036',
                borderColor: '#ff4000',
                borderWidth: 2,
                pointRadius: 0
            }
        ]
    }
    const options = {
        layout: {
            beginAtZero: false,
            padding: {
                right: 10
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                suggestedMin: 0,
                suggestedMax: 5,
                pointLabels: {
                    color: '#818181',
                    font: {
                        size: 18
                    }
                },
                ticks: {
                    display: false,
                    stepSize: 2
                }
            }
        }
    }

    useEffect(() => {}, [])

    return (
        <div className='hexchart'>
            <Radar data={data} options={options} />
        </div>
    )
}

export default HexChart
