import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <>
            <div className='div404'></div>
            <h5>What have you done!?</h5>
            <p className='p404'>
                Please go back to the <Link to='/'>homepage</Link>{' '}
                or to <Link to='/projects'>projects</Link>
            </p>
        </>
    )
}

export default NotFound
