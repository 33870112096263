import ProjectNav from '../../components/ProjectNav'
import {
    misc1,
    misc2,
    misc3,
    misc4,
    misc5
} from '../../images/projects/misc/imagesMisc'
const Misc = () => {
    const nav = (
        <ProjectNav
            prevProj='amo'
            nextProj='logo'
            projYear={null}
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main misc'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Miscellaneous web projects</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>Bits and bobs.</p>
                        <ul className='techs'>
                            <li>UI / UX / Digital Design</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img src={misc1} alt='Deco' />
                    <p>
                        <b>2014</b> A quick concept & design proposal for a
                        sports microsite for DECO that should reflect liveliness
                        and imaginative ways of envolving users.
                    </p>
                    <img
                        src={misc2}
                        alt='Altran'
                        className='noborder'
                    />
                    <p>
                        <b>2014</b> Another one day proposal, this one for an
                        internal portal at Altran.
                    </p>
                    <img src={misc3} alt='Fula' />
                    <p>
                        <b>2013</b> Centro de Nutrição Fula was already live. I
                        helped expanding it with front end, designing some
                        elements and editing contents (Drupal).
                    </p>
                    <img src={misc4} alt='NOS' />
                    <p>
                        <b>2015</b> Proposal for NOS internal portal.
                    </p>
                    <img src={misc5} alt='Health Industry' />
                    <p>
                        <b>2011</b> Health Industry, a bold concept for a Dental
                        Clinic, inspired in mid-century graphic design and built
                        in Flash.
                    </p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Misc
