import ProjectNav from '../../components/ProjectNav'
import { lv1, lv2, lv3 } from '../../images/projects/lv/imagesLv'
const Lv = () => {
    const nav = (
        <ProjectNav
            prevProj='espap'
            nextProj='kudos'
            projYear='2018'
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main lv'>
            <div className='linha'></div>
            <section className='container'>
                <h1>LegalVision Website</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            Redesign and creation of new features for
                            Legalvision, legal services French company
                        </p>
                        <ul className='techs'>
                            <li>UX/UI</li>
                            <li>Design</li>
                            <li>Front End</li>
                            <li>Responsive</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img src={lv1} alt='Landing page' />
                    <p>Landing page.</p>
                    <img src={lv2} alt='Checkout' />
                    <p>Checkout page.</p>
                    <img src={lv3} alt='Product' />
                    <p>Product description.</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Lv
