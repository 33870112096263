import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import $ from 'jquery'

const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation()

    useEffect(() => {
        //window.scrollTo(0, 0)
        $('html, body').animate({ scrollTop: 0 }, 100)
    }, [pathname])

    return children || null
}

export default ScrollToTop
