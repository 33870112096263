import ProjectNav from '../../components/ProjectNav'
import {
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9
} from '../../images/projects/logo/imagesLogo'
const Logo = () => {
    const nav = (
        <ProjectNav
            prevProj='misc'
            nextProj={null}
            projYear={null}
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main misc logopage'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Logos & Miscellaneous graphics</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>Logos, print, etc.</p>
                        <ul className='techs'>
                            <li>UI / UX / Digital Design</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img
                        src={logo1}
                        alt='Philip Morris'
                        className='noborder noshadow'
                    />
                    <p>
                        <b>2015</b> Tabaqueira (Philip Morris).
                    </p>
                    <img src={logo2} alt='Altran' />
                    <p>
                        <b>2015</b> Altran.
                    </p>
                    <img
                        src={logo3}
                        alt='ccipe'
                        className='noborder noshadow'
                    />
                    <p>
                        <b>2013</b> Câmara de Comércio e Indústria Portugal
                        Egipto.
                    </p>
                    <img
                        src={logo4}
                        alt='Health Industry'
                        className='noborder noshadow'
                    />
                    <p>
                        <b>2011</b> Health Industry.
                    </p>
                    <img
                        src={logo5}
                        alt='Miscellaneous'
                        className='noborder noshadow'
                    />
                    <p>Miscellaneous logos.</p>
                    <img
                        src={logo6}
                        alt='3D Head'
                        className='noborder noshadow'
                    />
                    <p>
                        <b>2010 </b> Head (3D software and Photoshop).
                    </p>
                    <img src={logo7} alt='Card' className='noborder' />
                    <p><b>2012 </b> Card & letterhead preview.</p>
                    <img src={logo8} alt='Résumé for an Interior Designer' />
                    <p>
                        <b>2013 </b>{' '}
                        Résumé for an Interior Designer friend, conceived to
                        resemble a classical decoration magazine.
                    </p>
                    <img
                        src={logo9}
                        alt='Poster for a dental surgery course'
                        className='noborder'
                    />
                    <p>
                        <b>2013</b>{' '}
                        Poster for a dental surgery course. It draws attention
                        without shocking dentists - just everyone else.
                    </p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Logo
