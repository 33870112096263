import ProjectNav from '../../components/ProjectNav'
import { amo1, amo2, amo3, amo4 } from '../../images/projects/amo/imgAmo'
const Amo = () => {
    const nav = (
        <ProjectNav
            prevProj='maintra'
            nextProj='misc'
            projYear='2014'
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main amo'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Airbus AMO-TC</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            I went to Toulouse for a week to realize this
                            project. This private web application is used at
                            Airbus to keep track of developments, verification
                            tools and simulation models, for a new hydraulic
                            brake or a new wing design, for example.
                        </p>
                        <p>
                            The process was strictly phased and highly iterated
                            until the optimal result was achieved.
                        </p>
                        <ul className='techs'>
                            <li>UI / UX / Digital Design</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img
                        src={amo1}
                        alt='Minimalistic design'
                        className='noborder'
                    />
                    <p>
                        Minimalistic design, following the guidelines of
                        Airbus's Brand Manual
                    </p>
                    <img src={amo2} alt='Logo' />
                    <p>
                        Logo: original, vectorized and stylized. The logo was to
                        remain unchanged but I propposed anyway and the
                        vectorized version was accepted
                    </p>
                    <img
                        src={amo3}
                        alt='Wireframes'
                        className='noborder'
                    />
                    <p>Wireframes, before any design work</p>
                    <img
                        src={amo4}
                        alt='final screens'
                        className='noborder'
                    />
                    <p>A few final screens</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Amo
