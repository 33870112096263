import ProjectNav from '../../components/ProjectNav'
import {
    marinha1,
    marinha2,
    marinha3,
    marinha4
} from '../../images/projects/intra-ma/imagesMarinha'
const Maintra = () => {
    const nav = (
        <ProjectNav
            prevProj='kudos'
            nextProj='amo'
            projYear='2015'
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main amn marinha'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Portuguese Navy Intranet</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            The portuguese Navy's intranet is a massive project
                            with hundreds of pages, 100 GB of data and over
                            11000 users. It's conception and realization was
                            both challenging and rewarding.
                        </p>
                        <ul className='techs'>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>Front End</li>
                            <li>Responsive</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img src={marinha1} alt='Homepage' />
                    <p>
                        Homepage. Fixed area above and a custom area where each
                        user can choose, resize and drag widgets with varied
                        informations.
                    </p>
                    <img src={marinha2} alt='Newspage' />
                    <p>Newspage.</p>
                    <img src={marinha3} alt='Equipment detail page' />
                    <p>Equipment detail page.</p>
                    <img src={marinha4} alt='Admiral of the Fleet' />
                    <p>Page of the Admiral of the Fleet.</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Maintra
