import { useEffect } from 'react'
import $ from 'jquery'
import sr from '../modules/ScrollReveal'
import {
    ab,
    axa,
    best,
    continente,
    eristoff,
    nj,
    nos,
    pm,
    um,
    vodafone,
    worten
} from '../images/imgIndex'

const Brands = () => {
    useEffect(() => {
        let numeroSquares = $('.heart .sq').length
        const runSetTimeout = (i) => {
            setTimeout(function () {
                $('.heart .sq:eq(' + i + ')').addClass('visivel')
            }, 500 + 40 * i)
        }

        const runLogoCloud = () => {
            for (let i = 0; i < numeroSquares; i++) {
                runSetTimeout(i)
            }
        }

        sr.reveal('.heart', {
            duration: 0,
            viewFactor: 0.1,
            afterReveal: function () {
                runLogoCloud()
            }
        })

        return () => {
            clearTimeout(runSetTimeout)
        }
    }, [])

    return (
        <div className='heartbox box'>
            <a href='/#/projects/'>
                <div className='heart'>
                    <div className='half left'>
                        <div className='sq top1 r1'></div>
                        <div className='sq sqw3 sqh3 r2 vodafone'>
                            <img alt='img' src={vodafone} />
                        </div>
                        <div className='sq top1 r5'></div>
                        <div className='sq sqw2 sqh2 top2 axa'>
                            <img alt='img' src={axa} />
                        </div>
                        <div className='sq sqw6 sqh2 top3 r2 pmorris'>
                            <img alt='img' src={pm} />
                        </div>
                        <div className='sq sqw2 top2 r5 best'>
                            <img alt='img' src={best} />
                        </div>
                        <div className='sq sqw2 sqh2 top4 r0 mccann'>
                            <img alt='img' src={um} />
                        </div>
                        <div className='sq sqw3 top5 r2 netjets'>
                            <img alt='img' src={nj} />
                        </div>
                        <div className='sq sqw2 top5 r5 nos'>
                            <img alt='img' src={nos} />
                        </div>
                        <div className='sq sqw6 sqh2 top6 airbus'>
                            <img alt='img' src={ab} />
                        </div>
                        <div className='sq sqw4 sqh2 top8 eristoff'>
                            <img alt='img' src={eristoff} />
                        </div>
                        <div className='sq sqw3 top10 continente'>
                            <img alt='img' src={continente} />
                        </div>
                        <div className='sq sqw2 top11 worten'>
                            <img alt='img' src={worten} />
                        </div>
                        <div className='sq top12'></div>
                    </div>
                    <div className='half right'>
                        <div className='sq top1 r1'></div>
                        <div className='sq sqw3 sqh3 r2'></div>
                        <div className='sq top1 r5'></div>
                        <div className='sq sqw2 top2'></div>
                        <div className='sq sqw8 sqh2 top3 r0'>
                            <div className='gotoproj'>
                                <p>See some of my</p>
                                <h3>PROJECTS</h3>
                            </div>
                        </div>
                        <div className='sq sqw2 top2 r5'></div>
                        <div className='sq sqw2 top5 r0'></div>
                        <div className='sq sqw3 top5 r2'></div>
                        <div className='sq sqw2 top5 r5'></div>
                        <div className='sq sqw6 sqh2 top6'></div>
                        <div className='sq sqw4 sqh2 top8'></div>
                        <div className='sq sqw3 top10'></div>
                        <div className='sq sqw2 top11'></div>
                        <div className='sq top12'></div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Brands
