import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import ScrollToTop from './components/ScrollToTop'

import { AuthContextProvider } from './auth/auth-context'

ReactDOM.render(
    <AuthContextProvider>
        <HashRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </HashRouter>
    </AuthContextProvider>,
    document.getElementById('root')
)
