import ProjectNav from '../../components/ProjectNav'
import {
    kudos1,
    kudos2,
    kudos3,
    kudos4,
    kudos5
} from '../../images/projects/kudos/imagesKudos'
const Kudos = () => {
    const nav = (
        <ProjectNav
            prevProj='lv'
            nextProj='maintra'
            projYear='2012'
            projUrl={null}
        />
    )
    return (
        <div className='projectpage main kudos'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Kudos logo & website</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            Kudos is a Buckinghamshire-based company that helps
                            researchers and their institutions to increase the
                            visibility and impact of their published articles.
                        </p>
                        <p>
                            At{' '}
                            <a href='http://www.hypnotic.pt/' target='_blank' rel='noreferrer'>
                                Hypnotic Digital Agency
                            </a>{' '}
                            we were required to design the logo as well as the
                            website and it´s implementation. I was trusted the
                            entire process and everything went smoothly.
                        </p>
                        <p>
                            The website changed but it can still be visited{' '}
                            <a
                                target='_blank'
                                title='Kudos'
                                rel='noreferrer'
                                href='https://www.growkudos.com'>
                                here
                            </a>
                            .
                        </p>
                        <ul className='techs'>
                            <li>Logo and Website Design</li>
                            <li>Front End</li>
                            <li>Wordpress</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img src={kudos1} alt='Homepage' />
                    <p>
                        Homepage. Diagonals were tricky because CSS3 was still
                        not an option.
                    </p>
                    <img src={kudos2} alt='The logo' />
                    <p>The logo. The butterfly was a requirement.</p>
                    <img src={kudos3} alt='Description of the company' />
                    <p>
                        Description of the company. We were told the{' '}
                        <a
                            href='https://en.wikipedia.org/wiki/Butterfly_effect'
                            target='_blank'
                            rel='noreferrer'>
                            butterfly effect
                        </a>{' '}
                        was an important concept in the company.
                    </p>
                    <img src={kudos4} alt='Blog' />
                    <p>
                        Blog designed with Wordpress blog functionalities in
                        mind.
                    </p>
                    <img src={kudos5} alt='Team' />
                    <p>The team.</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Kudos
