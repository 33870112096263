import React from 'react'
import { Link } from 'react-router-dom'
import Pdf from '../docs/CV-Jorge-Soucasaux-Monteiro-2023.pdf'

const Cv = () => {
    return (
        <div className='main cvpage'>
            <div className='linha'></div>
            <section>
                <h1>Résumé</h1>
                <a
                    href={Pdf}
                    target='_blank'
                    rel='noreferrer'
                    className='download'
                    download>
                    Download CV
                    <i className='icon-down-open-big'></i>
                </a>
                <div className='extra'>
                    <ul>
                        <li>
                            <Link to='/projects'>
                                projects <i className='icon-heart'></i>
                            </Link>
                        </li>
                        <li>
                            <Link to='/skills'>
                                skills <i className='icon-compass-1'></i>
                            </Link>
                        </li>
                        <li>
                            <Link to='/contact'>
                                contact <i className='icon-phone'></i>
                            </Link>
                        </li>
                    </ul>

                    {/* <Link to='/projects'>
                        Want to check some projects?{' '}
                        <i className='icon-heart'></i>
                    </Link> */}
                </div>
            </section>
        </div>
    )
}

export default Cv
