import { useState, useRef, useContext, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import AuthContext from './auth-context'

const Password = () => {
  const history = useHistory()
  const passwordInputRef = useRef()
  const authCtx = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')

  const FBKEY = 'AIzaSyA9UoA0QYYigiWz7idEiCxR9E_NbpLj1o4'

  useEffect(() => {
    let url = history.location.hash.substring(1)
    setRedirectUrl(url)
  }, [history.location.hash])

  const submitHandler = (event) => {
    event.preventDefault()
    const enteredPassword = passwordInputRef.current.value

    setIsLoading(true)
    let url =
      'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' +
      FBKEY

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: 'visitor@soucasaux.com',
        password: enteredPassword,
        returnSecureToken: true
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        setIsLoading(false)
        if (res.ok) {
          return res.json()
        } else {
          return res.json().then((data) => {
            let errorMessage = 'Authentication failed!'
            throw new Error(errorMessage)
          })
        }
      })
      .then((data) => {
        const expirationTime = new Date(
          new Date().getTime() + +data.expiresIn * 1000
        )
        authCtx.login(data.idToken, expirationTime.toISOString())
        history.replace(redirectUrl)
      })
      .catch((err) => {
        alert(err.message)
      })
  }

  return (
    <div className='main passwordpage'>
      <section className='formbox'>
        <h2>PASSWORD</h2>
        <form onSubmit={submitHandler} className='box input-group'>
          <input
            type='password'
            id='password'
            ref={passwordInputRef}
            className='pass'
            required
          />
          <button className='submeter' type='submit'>
            {!isLoading && <i className='icon-right-open-big'></i>}
            {isLoading && <i className='icon-rocket'></i>}
          </button>
        </form>
        <p>
          Don't have one / Not working?
          <Link to='/contact'> Contact me</Link>
        </p>
      </section>
    </div>
  )
}

export default Password
