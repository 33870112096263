import { useEffect } from 'react'
import $ from 'jquery'

const SkillBox = () => {
  useEffect(() => {
    let visibleTr = 1
    const showHighlights = () => {
      $('.skills .tr').fadeOut(500)

      $('.skills .tr:eq(' + visibleTr + ')').fadeIn(500)

      if (visibleTr < $('.skills .tr').length - 1) {
        visibleTr += 1
      } else {
        visibleTr = 0
      }
    }
    const cycleTds = () => {
      showHighlights()
    }
    $('.skills .tr:eq(0)').slideDown()
    let cycle = setInterval(cycleTds, 2000)

    return () => {
      $('.skills .tr').fadeOut(0)
      clearInterval(cycle)
      visibleTr = 1
    }
  }, [])

  return (
    <div className='box skillbox'>
      <div className='skills'>
        <div className='tr'>
          <div className='td'></div>
          <div className='td'>&lt;</div>
          <div className='td'>F</div>
          <div className='td'>R</div>
          <div className='td'>O</div>
          <div className='td'>N</div>
          <div className='td'>T</div>
          <div className='td'>_</div>
          <div className='td'>E</div>
          <div className='td'>N</div>
          <div className='td'>D</div>
          <div className='td'>/</div>
          <div className='td'>&gt;</div>
          <div className='td'></div>
        </div>
        <div className='tr'>
          <div className='td'>D</div>
          <div className='td'>I</div>
          <div className='td'>G</div>
          <div className='td'>I</div>
          <div className='td'>T</div>
          <div className='td'>A</div>
          <div className='td'>L</div>
          <div className='td'>:</div>
          <div className='td'>D</div>
          <div className='td'>E</div>
          <div className='td'>S</div>
          <div className='td'>I</div>
          <div className='td'>G</div>
          <div className='td'>N</div>
        </div>
        <div className='tr'>
          <div className='td'>I</div>
          <div className='td'>N</div>
          <div className='td'>F</div>
          <div className='td'>O</div>
          <div className='td'>.</div>
          <div className='td'>A</div>
          <div className='td'>R</div>
          <div className='td'>C</div>
          <div className='td'>H</div>
          <div className='td'>/</div>
          <div className='td'>T</div>
          <div className='td'>U</div>
          <div className='td'>R</div>
          <div className='td'>E</div>
        </div>
        <div className='tr'>
          <div className='td'></div>
          <div className='td'></div>
          <div className='td'>$</div>
          <div className='td'>W</div>
          <div className='td'>O</div>
          <div className='td'>R</div>
          <div className='td'>D</div>
          <div className='td'>P</div>
          <div className='td'>R</div>
          <div className='td'>E</div>
          <div className='td'>S</div>
          <div className='td'>S</div>
          <div className='td'></div>
          <div className='td'></div>
        </div>
        <div className='tr'>
          <div className='td'></div>
          <div className='td'></div>
          <div className='td'>3</div>
          <div className='td'>D</div>
          <div className='td'></div>
          <div className='td'>\</div>
          <div className='td'></div>
          <div className='td'>V</div>
          <div className='td'>I</div>
          <div className='td'>D</div>
          <div className='td'>E</div>
          <div className='td'>O</div>
          <div className='td'></div>
          <div className='td'></div>
        </div>
        <div className='tr'>
          <div className='td'>B</div>
          <div className='td'>R</div>
          <div className='td'>A</div>
          <div className='td'>N</div>
          <div className='td'>D</div>
          <div className='td'>I</div>
          <div className='td'>N</div>
          <div className='td'>G</div>
          <div className='td'>+</div>
          <div className='td'>=</div>
          <div className='td'>L</div>
          <div className='td'>O</div>
          <div className='td'>G</div>
          <div className='td'>O</div>
        </div>
      </div>
    </div>
  )
}

export default SkillBox
