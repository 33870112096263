import ProjectNav from '../../components/ProjectNav'
import {
    iefp1,
    iefp2,
    iefp3,
    iefp4,
    iefp5,
    iefp6,
    iefp7,
    iefp8,
    iefp9,
    areas,
    palco
} from '../../images/projects/iefp/imagesIefp'
const Iefp = () => {
    const nav = (
        <ProjectNav
            prevProj='netjets'
            nextProj='poch'
            projYear='2013'
            projUrl='https://www.iefp.pt'
        />
    )
    return (
        <div className='projectpage main iefp'>
            <div className='linha'></div>
            <section className='container'>
                <h1>IEFP Institutional Website & Intranet</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            This was the largest project I've been involved.
                            This is the government's organism responsible for
                            employment and professional training.
                        </p>
                        <h5>Concept</h5>
                        <p>
                            Before kickoff I analysed exhaustively the old
                            website and based my proposal on a drastic
                            simplification of it's architecture. Many confusing
                            areas and menus were accommodated to my suggestion:
                            6 areas, each with it's differentiating color:
                            employment, training, programmes, the institute,
                            help and contacts. This is helpful for everyone and
                            takes into account visually and cognitively impaired
                            users.
                        </p>
                        <img alt='areas' src={areas} />
                        <h6>Six areas, six colors.</h6>
                        <h5>Development</h5>
                        <p>
                            During the development I produced all the designs,
                            HTML, CSS & Javascript. The requirements included
                            compatibility IE8+, responsiveness and Accesibility
                            WCAG 2.0 - AA. I created a highly flexible model
                            where any backoffice user could choose from several
                            combinations of page layouts. I did so conceiving
                            HTML in a modular way and converting it to Liferay's
                            template language (Apache Velocity).
                        </p>

                        <h5>Migration</h5>
                        <p>
                            The migration of contents to the new website
                            involved dozens of employees. Me and the lead
                            Liferay developer conducted training sessions to all
                            of them during 2 weeks. After that the help requests
                            were countless and we evaluated each one, fitting
                            the content to our model or designing and developing
                            new templates when necessary.
                        </p>

                        <h5>Release</h5>
                        <p>
                            Upon release I was requested a presentational video
                            to send to the press. The video was also used at the
                            press release which I attended as speaker.
                        </p>
                        <img alt='stage' src={palco} />
                        <h6>
                            Press release with Altran Portugal's CEO, IEFP's
                            president, vice president and me.
                        </h6>

                        <h5>Other projects</h5>
                        <p>
                            After the project IEFP took measures so that all
                            it's websites and image would match the graphic line
                            defined by my work at the institutional website. I
                            continued working with the institute on the
                            intranet, number dispenser for the job centres, etc.
                        </p>
                        <ul className='techs'>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>Front End</li>
                            <li>Responsive</li>
                            <li>WCAG 2.0 - AA</li>
                            <li>Liferay</li>
                            <li>Apache Velocity</li>
                            <li>Content Editing</li>
                            <li>Video</li>
                            <li>Backoffice Training</li>
                            <li>Graphic Design</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img alt='Homepage' src={iefp1} />
                    <p>
                        Homepage with news and job offers above the fold. Each
                        one of the hundreds of job and training offer types has
                        a different photo.
                    </p>
                    <img alt='Job center' src={iefp2} />
                    <p>
                        Job center page with Google Maps and real time ticket
                        numbers.
                    </p>
                    <img alt='Job offer' src={iefp3} className='noborder' />
                    <p>Job offer detail.</p>
                    <img alt='Homepage' src={iefp4} />
                    <p>
                        Multiple step menu responsive through very simple HTML,
                        CSS & JS.
                    </p>
                    <img alt='Apps' src={iefp5} />
                    <p>Applications overview, after login.</p>
                    <img alt='CV Detail' src={iefp6} />
                    <p>CV edit detail.</p>
                    <img alt='Intranet' src={iefp7} />
                    <p>
                        Intranet homepage with parallax effect (fancy in 2013).
                    </p>
                    <img alt='search results' src={iefp8} />
                    <p>
                        Intranet homepage with search results divided into
                        categories.
                    </p>
                    <img
                        alt='Interface for ticket dispenser'
                        src={iefp9}
                        className='noborder'
                    />
                    <p>Interface for ticket dispenser and TV display.</p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Iefp
