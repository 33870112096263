import ProjectNav from '../../components/ProjectNav'
import { amn1, amn2, amn3, amn4 } from '../../images/projects/amn/imagesAmn'
const Amn = () => {
    const nav = (
        <ProjectNav
            prevProj={null}
            nextProj='netjets'
            projYear='2014'
            projUrl='http://www.amn.pt/Paginas/Homepage.aspx'
        />
    )
    return (
        <div className='projectpage main amn'>
            <div className='linha'></div>
            <section className='container'>
                <h1>Autoridade Marítima Nacional</h1>
                <div className='desc'>
                    <div className='txt'>
                        <p>
                            Institutional website for the Portuguese Maritime
                            Authority (AMN). This structure, part of the
                            Portuguese Navy, is responsible for the protection
                            and control of the national coastline up to 30 miles
                            ashore.
                        </p>
                        <h5>A large military structure</h5>
                        <p>
                            The AMN's roles include the Maritime Police,
                            Harbourmasters, Lighthouses, rescue at sea and
                            beaches (lifeguards, lifeboats, etc.) and marine
                            pollution control. It took a lot of diplomacy and
                            assertiveness to come up with graphic and
                            communicational solutions pleasing to all parts.
                        </p>
                        <ul className='techs'>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>Front end</li>
                            <li>Responsive</li>
                            <li>Content</li>
                        </ul>
                    </div>
                    {nav}
                </div>
                <div className='gallery'>
                    <img alt='homepage' src={amn1} />
                    <p>
                        Full homepage. Emphasis on the news which can literally
                        save lives.
                    </p>
                    <img alt='Harbourmaster page' src={amn2} />
                    <p>
                        Harbourmaster page with jurisdiction area map, real-time warnings,
                        news and other informations.
                    </p>
                    <img alt='mobile' src={amn3} className='noborder' />
                    <p>
                        Optimized for mobile. Special attention to performance, anticipating slow connections at sea.
                    </p>
                    <img
                        alt='Vector illustrations'
                        src={amn4}
                        className='noborder'
                    />
                    <p>
                        The website should keep distance from a military feel.
                        Custom vector illustrations for each of the AMN's main areas.
                    </p>
                    <div className='altnav'>{nav}</div>
                </div>
            </section>
        </div>
    )
}

export default Amn
