import { Link } from 'react-router-dom'

const MobileContacts = () => {
    return (
        <div className='mobiledevice listcontact'>
            <ul>
                <li>
                    <a
                        href='https://pt.linkedin.com/in/jorgesoucasaux'
                        rel='noreferrer'
                        title='https://pt.linkedin.com/in/jorgesoucasaux'
                        target='_blank'>
                        <i className='icon-linkedin-1'></i>
                        <p>Linkedin</p>
                    </a>
                </li>
                <li>
                    <a
                        href='https://github.com/scsx'
                        title='Linkedin'
                        rel='noreferrer'
                        target='_blank'>
                        <i className='icon-github'></i>
                        <p>Github</p>
                    </a>
                </li>
                <li>
                    <Link to='/cv'>
                        <i className='icon-adult'></i>
                        <p>Resumée</p>
                    </Link>
                </li>
                <li>
                    <a href='/#/projects'>
                        <i className='icon-heart'></i>
                        <p>Projects</p>
                    </a>
                </li>
                <li>
                    <a
                        href='https://open.spotify.com/user/1179088849'
                        target='_blank'
                        rel='noreferrer'>
                        <i className='icon-spotify'></i>
                        <p>Spotify</p>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default MobileContacts
