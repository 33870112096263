import React from 'react'
import { Link } from 'react-router-dom'
import {
    amn,
    netjets,
    iefp,
    poch,
    espap,
    lvlp,
    kudos,
    intrama,
    amo,
    misc,
    logo
} from '../images/imgIndex'

const Projects = () => {
    return (
        <div className='main projpage'>
            <div className='linha'></div>
            <h1>PROJECTS</h1>
            <div className='allprojs'>
                <ul className='clearfix'>
                    <li>
                        <Link to='/projects/amn'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${amn})`
                                }}></div>
                            <h3>Institutional Website</h3>
                            <p>Portuguese Navy - AMN</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/netjets'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${netjets})`
                                }}></div>
                            <h3>
                                Environmental Report{' '}
                                <i className='icon-lock'></i>
                            </h3>
                            <p>NetJets</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/iefp'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${iefp})`
                                }}></div>
                            <h3>
                                Institutional Website & Intranet{' '}
                            </h3>
                            <p>IEFP</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/poch'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${poch})`
                                }}></div>
                            <h3>Institutional Website</h3>
                            <p>POCH - Portugal 2020</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/espap'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${espap})`
                                }}></div>
                            <h3>Institutional Website & Intranet</h3>
                            <p>eSPap</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/lv'>
                            <div
                                className='thumb'
                                style={{ backgroundImage: `url(${lvlp})` }}></div>
                            <h3>Website</h3>
                            <p>Legalvision</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/kudos'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${kudos})`
                                }}></div>
                            <h3>Logo & Website</h3>
                            <p>Kudos</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/maintra'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${intrama})`
                                }}></div>
                            <h3>
                                Intranet <i className='icon-lock'></i>
                            </h3>
                            <p>Portuguese Navy</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects/amo'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${amo})`
                                }}></div>
                            <h3>
                                AMO-TC <i className='icon-lock'></i>
                            </h3>
                            <p>Airbus</p>
                        </Link>
                    </li>
                    <li className='misc'>
                        <Link to='/projects/misc'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${misc})`
                                }}></div>
                            <h3>Miscellaneous Web</h3>
                        </Link>
                    </li>
                    <li className='logos'>
                        <Link to='/projects/logo'>
                            <div
                                className='thumb'
                                style={{
                                    backgroundImage: `url(${logo})`
                                }}></div>
                            <h3>Logos & Miscellaneous graphics</h3>
                        </Link>
                    </li>
                    <li className='nextproj'>
                        <Link to='/contact'>
                            <h3>Next project? Contact me</h3>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Projects
