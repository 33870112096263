import Cube from "../components/Cube"

const Contact = () => {
    return (
        <div className='main ctctpage'>
            <section className='page contact' id='contact'>
                <h1>CONTACT</h1>
                <Cube />
            </section>
            <div className='linha'></div>
        </div>
    )
}

export default Contact
